<template>
  <b-container>
    <h1>General Data Protection Regulation (GDPR)</h1>
    <p>
      Under the GDPR regulations, should you wish for your data to be deleted,
      please send out a request to the relevant emails in the following list:
    </p>
    <b-table striped hover :items="items"></b-table>
  </b-container>
</template>
<script>
export default {
    data(){
        return { 
            items:[
                { Game:'Shoot the Number: Merge 2048', Email: 'liyangsamuel@novelpro.cn', },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
h1 {
  text-align: center !important;
  margin: 1.25rem 0;
}
p{
    text-align: center;
}
h1, h2 {
    text-align: center;
}
</style>